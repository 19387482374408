// Typography related styles

// Headings
/* stylelint-disable @stylistic/selector-list-comma-newline-after */

h1, .h1 {
  line-height: $h1-line-height;
}

h2, .h2 {
  line-height: $h2-line-height;
}

h3, .h3 {
  line-height: $h3-line-height;
}

h4, .h4 {
  line-height: $h4-line-height;
}

h5, .h5 {
  line-height: $h4-line-height;
}

h6, .h6 {
  line-height: $h4-line-height;
}


// Link inside headings

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  a {
    color: var(--#{$prefix}heading-color);
    text-decoration: none;
  }
}
/* stylelint-enable @stylistic/selector-list-comma-newline-after */


// Blockquote

.blockquote {
  font-weight: $blockquote-font-weight;
  color: $blockquote-color;
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y * .5;
}


// Inline list

.list-inline {
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacer * .625 $list-inline-padding;
  margin-bottom: $spacer * .75;
}
