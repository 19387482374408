// Lightbox / gallery component
// based on https://github.com/biati-digital/glightbox


// Fix issue with sticky elements when Glightbox is open

.glightbox-open {
  overflow: clip;
}

@-moz-document url-prefix() {
  .glightbox-open {
    overflow: hidden;
  }
}


// Remove shadow from Glightbox images

.glightbox-clean .gslide-media {
  box-shadow: none;
}


// Change backdrop (overlay) color

.glightbox-mobile .goverlay,
.goverlay {
  background: var(--#{$prefix}body-bg);
}


// Override Glightbox buttons

.glightbox-clean {
  .gprev,
  .gnext,
  .gclose {
    width: 2.5rem;
    height: 2.5rem;
    svg {
      width: 1.25rem;
    }
    @include media-breakpoint-up(md) {
      width: 3rem;
      height: 3rem;
      svg {
        width: 1.5rem;
      }
    }
    path {
      fill: currentcolor;
    }
  }
}

.glightbox-clean .gclose {
  opacity: 1;
}

.glightbox-button-hidden {
  display: none;
}

.gbtn.focused {
  outline: none;
  box-shadow: 0 0 0 .25rem $focus-ring-color-dark;
}
