// Override close button color and size inside alert

.alert .btn-close {
  --#{$prefix}btn-close-size: .6875em;
  --#{$prefix}btn-close-opacity: .75;
  --#{$prefix}btn-close-hover-opacity: 1;

  padding: $alert-padding-y * 1.375 $alert-padding-x;
}

@each $color, $value in map-remove($theme-colors, "secondary", "dark", "light") {
  .alert-#{$color} .btn-close {
    --#{$prefix}btn-close-bg: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{darken($value, 5%)}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>"))};

    filter: none;
  }
}
